import React, { useEffect, useRef } from 'react';
import Container from '../../../Container';

type CookieTableBlock = {
  url: string;
};

type Props = CookieTableBlock;

export function CookieTable({ url }: Props) {
  const ref = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = url;
    ref.current?.insertAdjacentElement('beforebegin', script);
  }, []);

  return (
    <Container width="tight">
      <div ref={ref} />
    </Container>
  );
}
