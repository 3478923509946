import React, { useCallback, useMemo } from 'react';
import styled from 'styled-components';

// Helpers
import mq from '~/App/helpers/mq';
import { heading4, arrowLink } from '~/App/helpers/mixins';
import { containerWidthsPx } from '~/App/config/containerWidths';

// Local components
import SocialMediaItems from './components/SocialMediaItems';
import BrandNegative from './components/BrandNegative';
import NinetyLogoNegative from './components/NinetyLogoNegative';
import GoogleTranslate from './components/GoogleTranslate';
import { FeatureToggleEnabled } from '~/App/contexts/FeatureToggles';

//Shared components
import Link from '~/App/shared/components/Link';

// Remember to update 404-handlebar template as well
const navigationItems = [
  {
    url: '/stod-oss',
    name: 'Stöd oss'
  },
  {
    url: '/om-oss',
    name: 'Om oss'
  },
  {
    url: 'https://jobb.cancerfonden.se/',
    name: 'Jobba hos oss'
  },
  {
    url: '/om-oss/personlig-integritet-och-cookies',
    name: 'Integritetspolicy'
  },
  {
    url: 'javascript: Cookiebot.renew()',
    name: 'Cookieinställningar'
  },
  {
    url: '/stod-oss/foretag',
    name: 'Företag'
  },
  {
    url: '/press',
    name: 'Press'
  },
  {
    url: '/utbildning',
    name: 'För sfi-skolor'
  },
  {
    url: '/om-oss/about',
    name: 'In English'
  }
];

const FooterMainContainer = styled.div`
  background: ${(props) => props.theme.colors.darkBlue};
  color: ${(props) => props.theme.colors.white};
  text-align: left;
  padding-bottom: 10rem;

  ${mq('≥medium')`
    padding-left: 2rem;
    padding-right: 2rem;
    padding-bottom: 5rem;
  `};
`;

const FooterColumnsWrapper = styled.div`
  padding: 0 0 1rem 0;

  ${mq('≥small')`
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      max-width: ${containerWidthsPx.normal};
      margin: 0 auto;
  `};
`;

const CFLogoWrapper = styled.div`
  width: 100%;
  padding: 2rem;

  ${mq('≥small')`
    padding-bottom: 0;
  `};
`;

const FooterColumn = styled.div`
  padding: 2rem;
  line-height: 1.625;

  &:nth-child(even) {
    padding: 0 2rem;
  }

  &:last-child {
    padding-bottom: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  ${mq('≥small')`
    width: 50%;
    padding: 2rem;

    &:nth-child(even) {
      padding: 2rem;
    }

    &:last-child {
      padding: 2rem;
    }
  `};

  ${mq('≥medium')`
    width: 25%;
    padding: 2rem;

    &:nth-child(even) {
      padding: 2rem;
    }

    &:last-child {
      padding: 2rem 2rem 2rem 0;
    }
  `};

  ${mq('≥large')`
    width: 24%;

    &:nth-child(2) {
      width: 27%;
    }

    &:last-child {
      width: 25%;
      padding: 2rem;
    }
  `};
`;

const FooterHeading = styled.h4`
  ${heading4};
  text-transform: uppercase;
  padding-top: 2rem;
  margin-bottom: 0.25rem;

  &:first-child {
    padding-top: 0;
  }

  ${mq('≥small')`
    font-size: 1rem;
    line-height: 1.25;
  `};
`;

const NavigationLinksList = styled.ul`
  li:last-child {
    margin-top: 1rem;
  }
`;

const ArrowLinkNegative = styled(Link)`
  ${arrowLink};
  color: ${(props) => props.theme.colors.white};
  font-size: 1rem;

  &:hover {
    color: ${(props) => props.theme.themeColors.accentBlue};
  }

  &::after {
    color: inherit;
  }

  &:hover::after {
    color: inherit;
  }
`;

const FooterLink = styled.a`
  color: ${(props) => props.theme.colors.white};
  text-decoration: underline;

  &:hover {
    color: ${(props) => props.theme.themeColors.accentBlue};
  }

  &[href^='tel:'] {
    text-decoration: none;
  }
`;

const SocialMediaWrapper = styled.div`
  /*padding: 2rem 0 1rem 0;*/
  //padding: 0rem 0 1rem 0;
  width: 100%;
  max-width: 13.5rem;
`;

const NinetyLogoWrapper = styled.div`
  padding-top: 2rem;

  ${mq('≥medium')`
    text-align: right;
  `};
`;

const SignUpFlexWrapper = styled.div`
  ${mq('≥medium')`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-between;
  `};
`;

export function Footer() {
  const renderNavigationItem = useCallback(
    (navigationItem, index) => (
      <li key={'navigationItem' + index}>
        <ArrowLinkNegative
          to={navigationItem.url}
          className={navigationItem.class}
        >
          {navigationItem.name}
        </ArrowLinkNegative>
      </li>
    ),
    []
  );

  return useMemo(
    () => (
      <div>
        <FooterMainContainer>
          <FooterColumnsWrapper>
            <CFLogoWrapper>
              <BrandNegative />
            </CFLogoWrapper>

            <FooterColumn>
              <div>
                <FooterHeading>Postadress</FooterHeading>
                <div>Cancerfonden</div>
                <div>101 55 Stockholm</div>
                <FooterHeading>Besöksadress</FooterHeading>
                <div>David Bagares gata 5</div>
                <FooterHeading>Org.Nr</FooterHeading>
                <div>802005-3370</div>
              </div>
            </FooterColumn>

            <FooterColumn>
              <FooterHeading>Ge en gåva</FooterHeading>
              <div>Swish: 901 95 14</div>
              <div>PG: 901986-0</div>
              <div>BG: 901-9514</div>
              <FooterHeading>Kontakt</FooterHeading>
              <div>
                <FooterLink href="mailto:info@cancerfonden.se">
                  info@cancerfonden.se
                </FooterLink>
                <br />
                <FooterLink href="tel:+46101991010">010-199 10 10</FooterLink>
              </div>
              <div>
                Från utlandet:
                <br />
                <FooterLink href="tel:+46101991010">
                  +(46) 10-199 10 10
                </FooterLink>
              </div>
              <ArrowLinkNegative to="/om-oss/kontakt">
                Fler kontaktvägar
              </ArrowLinkNegative>
            </FooterColumn>

            <FooterColumn>
              <FooterHeading>Länkar</FooterHeading>
              <NavigationLinksList>
                {navigationItems.map(renderNavigationItem)}
              </NavigationLinksList>
              <FeatureToggleEnabled feature="FEATURE_GOOGLE_TRANSLATE">
                <GoogleTranslate />
              </FeatureToggleEnabled>
            </FooterColumn>

            <FooterColumn>
              <SignUpFlexWrapper>
                {/* <NewsLetterSignUpButton href="/nyhetsbrev">
                  Få vårt nyhetsbrev
                </NewsLetterSignUpButton> */}
                <SocialMediaWrapper>
                  <SocialMediaItems />
                </SocialMediaWrapper>
              </SignUpFlexWrapper>
              <NinetyLogoWrapper>
                <NinetyLogoNegative />
              </NinetyLogoWrapper>
            </FooterColumn>
          </FooterColumnsWrapper>
        </FooterMainContainer>
      </div>
    ),
    [renderNavigationItem]
  );
}
