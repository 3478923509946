export const blockTemplates = {
  faq: 'faq',
  step: 'step',
  hero: 'hero',
  image: 'image',
  share: 'share',
  graph: 'graph',
  lawly: 'lawly',
  wufoo: 'wufoo',
  thanks: 'thanks',
  content: 'content',
  related: 'related',
  divider: 'divider',
  fileList: 'fileList',
  linkList: 'linkList',
  shorthand: 'shorthand',
  slideshow: 'slideshow',
  dataBlock: 'dataBlock',
  emailLeads: 'emailLeads',
  twoUpMedia: 'twoUpMedia',
  supporters: 'supporters',
  breadcrumbs: 'breadcrumbs',
  productGrid: 'productGrid',
  productList: 'productList',
  donationBar: 'donationBar',
  swish: 'swish',
  cookieTable: 'cookieTable'
};
