import { DefaultThemeColors } from 'styled-components';

const colors: DefaultThemeColors = {
  black: '#000000',
  darkCharcoal: '#333333',
  charcoal: '#4A4A4A',
  doveGray: '#666666',
  gray: '#888888',
  silverChalice: '#A8A8A8',
  dust: '#aaaaaa',
  silver: '#cccccc',
  lightDust: '#d4d4d4',
  alto: '#dddddd',
  gallery: '#EAEAEA',
  seashell: '#F1F1F1',
  smoke: '#f4f4f4',
  wildSand: '#f4f4f4',
  grayAlabaster: '#fbfbfb',
  white: '#ffffff',
  seance: '#70227b',
  chambray: '#3b5998',
  oceanBlue: '#60a1cd',
  danube: '#78afd5',
  dodgerBlue: '#4099ff',
  hoverBlue: '#3547AD',
  blueBell: '#9392c0',
  lightBlue: '#F0F9FC',
  iceberg: '#d1ecf1',
  deepSeaGreen: '#085360',
  botticelli: '#cfdfe5',
  wispPink: '#fce7ec',
  wispPinkLight: '#fef6f8',
  wispPink2: '#fdecf2',
  pink: '#ca0070',
  rose: '#e2007d',
  catskillWhite: '#f6fafb',
  chantilly: '#f8bfcd',
  shocking: '#dd8aac',
  vanillaIce: '#eed2d8',
  hopbush: '#cc608d',
  azalea: '#f7ced7',
  amour: '#f8e5eb',
  carissma: '#ec9bba',
  darkBlue: '#001489',
  polar: '#dff1f9',
  lightPolar: '#f0f9fc',
  christmasRed: '#BF0A0A',
  monza: '#d60b0b',
  errorRed: '#ba0913',
  errorRedDark: '#701923',
  errorRedMedium: '#f8d7db',
  errorRedLight: '#d61520',
  lightRed: '#F9F2F3',
  skeptic: '#d4eddb',
  crusoe: '#004a12',
  springRain: '#a8c5a6',
  apple: '#54aa45',
  lightApple: '#40b92b',
  darkApple: '#20a922',
  lightGreen: '#F3F7F3',
  deYork: '#7cb879',
  norway: '#94BA92',
  blanchedAlmond: '#fff3cd',
  gambogeOrange: '#7e5d00',
  transparent: 'transparent',
  butterMilk: '#ffefb3',
  darkOrange: '#BA7909',
  sunDown: '#ffb3b3',
  sangria: '#9c0000',
  lightOrange: '#F9F9F2',

  // new graphic identity accent colors
  pastelBlue: '#95D4EE',
  pastelPink: '#EC9BBA',
  pastelGreen: '#AFCCAE',
  accentYellow: '#F4C65A',
  hoverPastelPink: '#F0AFC6',

  // brand colors
  facebook: '#3a589b',
  twitter: '#1cb7eb'
};

export default colors;
